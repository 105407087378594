.dare-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; /* Ensure it covers the full viewport width */
  height: 100vh; /* Ensure it covers the full viewport height */
  position: fixed; /* Position it on top of all other content */
  top: 0;
  left: 0;
  background-color: black;
  color: white;
  font-size: 2rem;
  text-align: center;
  z-index: 1000; /* High z-index to ensure it's above other elements */
}

.fade-in {
  animation: fadeIn 2s forwards; /* Use forwards to maintain the final state */
}

@keyframes fadeIn { 
  0% { opacity: 0; }
  100% { opacity: 1; } /* Ends at full opacity and stays there */
}

/* Optional: If you want a separate fade-out effect */
.fade-out {
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}


/* Positioning the buttons at the bottom of the screen */
.feedback-buttons {
  position: absolute;
  bottom: 70px; /* Adjust the distance from the bottom */
  width: 100%; /* Ensure it spans the full width of the container */
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
}

/* Styling the buttons without background and making them larger */
.feedback-buttons button {
  background: none; /* No background */
  border: none; /* No border */
  font-size: 2em; /* Increase the size of the emoji */
  padding: 10px; /* Add some padding for better clickability */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: transform 0.2s; /* Smooth transition on hover */
}

.feedback-buttons button:hover {
  transform: scale(1.2); /* Slightly enlarge on hover for interactivity */
}

/* Smaller font for feedback text */
.feedback-text {
  font-size: 0.8em; /* Smaller text size */
  margin-right: 10px; /* Space between text and buttons */
  color: #666; /* Lighter color for softer look */
}


