.intro-container {
    text-align: center;
    margin-top: -110px;
  }
  
  .dare {
    font-size: 90px; /* Increased font size */
    animation: fadeIn 1s ease-in-out forwards; /* Fade-in effect */
    margin-bottom: -10px; /* Reduce space below Dare */
  }
  
  .toDrink {
    font-size: 36px; /* Increased font size */
    margin-top: 0; /* Eliminate additional space above to Drink */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  