.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This centers children vertically in the container */
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}


#dare-to-drink {
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%; /* Ensure it spans the full width */
}

#questions {
  background-image: url("./images/background-image-2.jpeg");
  background-size: cover;
  background-position: center;
  color: black;
  text-align: center;
  width: 100%; /* Ensure it spans the full width */
  flex-grow: 1; /* This will allow it to expand and fill the available space */
}
