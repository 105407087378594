/* Questions.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  gap: 20px;
}

.question-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 600px;
}

.question-text {
  border: 2px solid white; /* White border */
  padding: 20px;
  font-size: 18px;
  background-color: black; /* Black background */
  color: white; /* White text */
  margin: 10px 0; /* Vertical spacing */
  border-radius: 10px; /* Slightly rounded corners for the text box */
  width: 100%; /* Adjust width as needed */
}

.answers-container {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Ensure answers are spaced out */
}

.answer-box {
  width: 45%; /* Adjust based on preference */
  display: flex;
  justify-content: center;
  background-color: black; /* Matching the question background */
  padding: 10px;
  border-radius: 10px; /* Rounded corners for aesthetic */
}

.button {
  background-color: black;
  color: white;
  border: none; /* Removes any border from the button */
  padding: 10px 15px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: white;
  color: black;
}

.fade-in {
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.ineligibility-notice {
  color: white;
  font-size: 18px;
}

.lower-box {
  position: absolute;
  bottom: 20px;
}
