html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.truth-or-dare-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.button-half {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-truth {
  color: #000;
  background-color: white;
}

.button-dare {
  color: white;
  background-color: #000;
}

.button-half:hover {
  opacity: 0.9; /* Slight visual feedback on hover */
}
